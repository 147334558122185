import type { SafetyCodeContext } from '@canalplus/types-acm';
import type { JSX } from 'react';
import offerZoneConfig from '../../../../../config/application/offerZone/default';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { useTranslation } from '../../../../lang';
import { offerZoneSelector } from '../../../../store/slices/application-selectors';
import styles from './SafetyCodeInputTooltipDesktop.css';

interface ISafetyCodeTooltipProps {
  safetyCodeContext: SafetyCodeContext;
}

/**
 * SafetyCodeTooltip
 *
 * This component is in charge to display a tooltip
 * on the "code oublié" text
 *
 * @param safetyCodeContext  ACM Context parentalCode
 */
/* eslint-disable react/no-danger */

export function SafetyCodeInputTooltipDesktop({
  safetyCodeContext,
}: ISafetyCodeTooltipProps): JSX.Element {
  const { t } = useTranslation();
  const offerZone = useInvariantSelector(offerZoneSelector);
  const phoneNumber = offerZoneConfig.offerZones[offerZone]?.phoneNumber || '';

  return (
    <div className={styles.SafetyCodeTooltip}>
      <p
        dangerouslySetInnerHTML={{
          __html: t(`SafetyDisclaimer.${safetyCodeContext}.tooltip`, {
            phoneNumber,
          }),
        }}
      />
    </div>
  );
}
