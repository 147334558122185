import {
  KEYCODE,
  getEncodedUrlWithProtocol,
  isClientSide,
} from '@canalplus/mycanal-commons';
import { PlatformGroup } from '@canalplus/sdk-core';
import { SafetyCodeContext } from '@canalplus/types-acm';
import classNames from 'classnames/bind';
import type { ChangeEvent, FocusEvent, JSX, KeyboardEvent } from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import IconError from '../../../assets/svg/error.svg';
import { AccountUrl, Queries } from '../../../constants/url';
import { useAppLocation } from '../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useGetAccountPathUrl } from '../../../helpers/hooks/useGetAccountPathUrl/useGetAccountPathUrl';
import { useTranslation } from '../../../lang';
import {
  hasReadDisclaimer,
  resetAdultError,
  updateAdultBrowsing,
  updateAdultError,
} from '../../../store/slices/adult';
import { adultErrorSelector } from '../../../store/slices/adult-selectors';
import {
  hostnameSelector,
  platformGroupSelector,
} from '../../../store/slices/application-selectors';
import {
  resetPurchaseCodeError,
  updatePurchaseCodeError,
} from '../../../store/slices/purchaseCode';
import { purchaseCodeErrorSelector } from '../../../store/slices/purchaseCode-selectors';
import Button from '../../Button/Button';
import { ONE_NUMBER_PATTERN, extractNumberFromInputId } from '../helpers';
import { useSubmitSafetyCode } from '../useSubmitSafetyCode';
import styles from './SafetyCodeInputFieldDesktop.css';
import SafetyCodeInputItem from './SafetyCodeInputItem/SafetyCodeInputItem';
import { SafetyCodeInputTooltipDesktop } from './SafetyCodeInputTooltipDesktop/SafetyCodeInputTooltipDesktop';
import type { SafetyCodeInputFieldDesktopProps } from './types';

const cx = classNames.bind(styles);
const NUMBER_OF_INPUT_ELEMENT = 4;

export function SafetyCodeInputFieldDesktop({
  safetyCodeContext,
  purchaseId,
  color,
  onSuccess,
}: SafetyCodeInputFieldDesktopProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string[]>([]);
  const [isLocked, toggleIsLocked] = useState(false);
  const inputsElement = useRef<HTMLInputElement[]>([]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const adultError = useSelector(adultErrorSelector);
  const purchaseCodeError = useSelector(purchaseCodeErrorSelector);
  const { pathname } = useAppLocation();
  const hostname = useSelector(hostnameSelector);
  const encodedHostnameWithProtocol = getEncodedUrlWithProtocol(
    hostname + pathname
  );
  const plaftormGroup = useSelector(platformGroupSelector);

  const isParentalCodeContext =
    safetyCodeContext === SafetyCodeContext.Parental;
  const isPurchaseCodeContext =
    safetyCodeContext === SafetyCodeContext.Purchase;

  const accountCodeURL = useGetAccountPathUrl(
    isParentalCodeContext ? AccountUrl.ParentalCode : AccountUrl.PurchaseCode,
    [[Queries.RedirectUri, encodedHostnameWithProtocol]]
  );

  const initializeFocusOnInput = () => inputsElement.current[0]?.focus();

  const focusNextInput = (inputId: number) =>
    inputsElement.current[inputId + 1]?.focus();

  const focusPrevInput = (inputId: number) =>
    inputsElement.current[inputId - 1]?.focus();

  const focusPreciseInput = (inputId: number) =>
    inputsElement.current[inputId]?.focus();

  const setInputElementValue = ({
    id,
    value,
  }: Partial<ChangeEvent<HTMLInputElement>['target']>) => {
    const inputId = extractNumberFromInputId(String(id));

    setInputValue((values) => {
      if (value) {
        values[inputId] = value;
      }

      return [...values];
    });
  };

  const submitSafetyCode = useSubmitSafetyCode({
    purchaseId,
    code: inputValue.join(''),
    safetyCodeContext,
    onSuccessCode: (validationToken) => {
      if (isParentalCodeContext) {
        // TODO: Refactor/migrate to onSuccess and without REDUX as much as possible
        dispatch(updateAdultBrowsing());
        dispatch(hasReadDisclaimer());
      }
      if (isPurchaseCodeContext) {
        onSuccess?.(validationToken);
      }
      if (isClientSide()) {
        window.scrollTo(0, 0);
      }
    },
    onWrongCode: () => {
      if (isParentalCodeContext) {
        dispatch(updateAdultError(t('SafetyDisclaimer.wrongCode')));
      }
      if (isPurchaseCodeContext) {
        dispatch(updatePurchaseCodeError(t('SafetyDisclaimer.wrongCode')));
      }
      setInputValue([]);
    },
    onLockedCode: () => {
      toggleIsLocked(true);
      setInputValue([]);
    },
  });

  useEffect(() => {
    if (inputValue.length === 0) {
      initializeFocusOnInput();
      return;
    }

    /**
     * When the user has filled the four inputs,
     * we submit the form
     * We use timeout to allow the user to see the last input
     */
    if (inputValue[NUMBER_OF_INPUT_ELEMENT - 1]) {
      const timer = setTimeout(async () => {
        await submitSafetyCode();
      }, 500);

      return () => clearTimeout(timer);
    }

    return;
  }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // We erase the error when the user taps the first input
    const canResetError =
      (!!adultError || !!purchaseCodeError) &&
      inputValue.length > 0 &&
      inputValue.length < NUMBER_OF_INPUT_ELEMENT;
    if (canResetError && isParentalCodeContext) {
      dispatch(resetAdultError());
    }
    if (canResetError && isPurchaseCodeContext) {
      dispatch(resetPurchaseCodeError());
    }
  }, [inputValue, adultError, purchaseCodeError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;

    if (RegExp(ONE_NUMBER_PATTERN).test(value)) {
      const inputId = extractNumberFromInputId(id);
      const canFocusNextInput =
        value.length === 1 && inputId < NUMBER_OF_INPUT_ELEMENT - 1;

      setInputElementValue(event.target);
      if (canFocusNextInput) {
        focusNextInput(inputId);
      }
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === KEYCODE.BACKSPACE) {
      const id = extractNumberFromInputId(
        document?.activeElement?.id as string
      );
      const canFocusPrevInput = id > 0 && id <= NUMBER_OF_INPUT_ELEMENT;
      const isActuallyFilled = inputsElement.current[id]?.value !== '';

      if (canFocusPrevInput) {
        if (isActuallyFilled) {
          setInputElementValue({ value: '', id: document?.activeElement?.id });
        } else {
          focusPrevInput(id);
          setInputElementValue({ value: '', id: `input-${id - 1}` });
        }
      } else {
        setInputElementValue({ value: '', id: 'input-0' });
        focusPreciseInput(0);
      }
    }
  };

  const handleFocus = ({
    target: { id },
  }: FocusEvent<HTMLInputElement, Element>) => {
    const inputId = extractNumberFromInputId(id);
    const lastInput = inputsElement.current[inputId > 0 ? inputId - 1 : 0];

    if (lastInput?.value === '') {
      const emptyInputId = inputsElement.current.findIndex(
        (input) => input.value === ''
      );

      focusPreciseInput(emptyInputId >= 0 ? emptyInputId : 0);
    }
  };

  const isOrangeBoxInParentalSafetyMode =
    plaftormGroup === PlatformGroup.Orange && isParentalCodeContext;

  const safetyCodeInputTitle = isOrangeBoxInParentalSafetyMode
    ? t(`SafetyDisclaimer.${safetyCodeContext}.enterCodeOrange`)
    : t(`SafetyDisclaimer.${safetyCodeContext}.safetyCodeTitle`);

  const safetyCodeCodeInputFieldTitle = isLocked
    ? t(`SafetyDisclaimer.${safetyCodeContext}.lockedTitle`)
    : safetyCodeInputTitle;

  const safetyCodeInputSubtitle = isOrangeBoxInParentalSafetyMode
    ? t(`SafetyDisclaimer.${safetyCodeContext}.safetyCodeInstructionOrange`)
    : t(`SafetyDisclaimer.${safetyCodeContext}.safetyCodeInstruction`);

  const safetyCodeInputFieldSubtitle = isLocked
    ? t(`SafetyDisclaimer.${safetyCodeContext}.lockedText`)
    : safetyCodeInputSubtitle;

  const safetyCodeInputFieldForget = isOrangeBoxInParentalSafetyMode
    ? t(`SafetyDisclaimer.${safetyCodeContext}.codeOrangeforget`)
    : t(`SafetyDisclaimer.${safetyCodeContext}.forgotten`);

  const renderInputs = useMemo(
    () => (
      <>
        {[...Array(NUMBER_OF_INPUT_ELEMENT).keys()].map((index) => {
          const inputId = `input-${index}`;
          return (
            <SafetyCodeInputItem
              onKeyDown={handleOnKeyDown}
              id={inputId}
              key={inputId}
              onChange={handleCodeChange}
              value={inputValue}
              inputRef={(elt: HTMLInputElement) =>
                (inputsElement.current[index] = elt) as any
              }
              onFocus={handleFocus}
            />
          );
        })}
      </>
    ),
    [inputValue] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div
      className={cx('SafetyCodeInputFieldDesktop', {
        'SafetyCodeInputFieldDesktop__locked': isLocked,
      })}
    >
      <h1 className={cx('SafetyCodeInputFieldDesktop__title')}>
        {safetyCodeCodeInputFieldTitle}
      </h1>
      <p className={cx('SafetyCodeInputFieldDesktop__subtitle')}>
        {safetyCodeInputFieldSubtitle}
      </p>

      {!isLocked ? (
        <>
          <div className={cx('SafetyCodeInputFieldDesktop__codeInput')}>
            {renderInputs}
          </div>
          {(!!adultError || !!purchaseCodeError) && (
            <div className={cx('SafetyCodeInputFieldDesktop__errorMessage')}>
              <IconError />
              {adultError || purchaseCodeError}
            </div>
          )}
          <div className={cx('SafetyCodeInputFieldDesktop__forgotten')}>
            <span className={cx('SafetyCodeInputFieldDesktop__forgottenLink')}>
              <a href={accountCodeURL}>{safetyCodeInputFieldForget}</a>
            </span>
            {isParentalCodeContext && (
              <div className={cx('SafetyCodeInputFieldDesktop__tooltip')}>
                <SafetyCodeInputTooltipDesktop
                  safetyCodeContext={safetyCodeContext}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <Button
          link={accountCodeURL}
          text={t(`SafetyDisclaimer.${safetyCodeContext}.resetSafetyCode`)}
          className={cx('SafetyCodeInputFieldDesktop__button')}
          isV5Style
          color={color}
        />
      )}
    </div>
  );
}

export default memo(SafetyCodeInputFieldDesktop);
